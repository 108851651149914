export default {
  hidden: true,

  storyId: 'blackmail',
  currentStep: 1,
  initialStep: 1,

  pushMessage: {
    message: 'I’m aware, salasana123 is your old password which I used to hack into your new passwords. You may not know me, and you are most likely wondering why you’re getting this mail, right?',
    from: '',
    type: 'email',
    pushCondition: 'home',
    closeOnClick: true,
  },

  email: {
    title: 'I’m aware, salasana123 is your old password which I used to hack into your new passwords. You may not know me, and you are most likely wondering why you’re getting this mail, right?',
    content: '<p>I installed a malware on the adult vids (sex sites) site, and there’s more, you visited this site to have fun (you know what I mean). Once you were there on the website, my malware took control of your browser.</p><p>It started operating as a keylogger and remote desktop protocol which gave me access to your webcam. Immediately after that my software collected your complete contacts from your Messenger, FB, and email. I created a double-screen video. First part shows the video you were watching (you have a good taste lol…), and the second part displays the recording of your webcam.</p><p>Precisely what should you do?</p><p>Well, I believe, $1900 is a fair price for your little secret. You will make the payment through Bitcoin (if you don’t know this, search “how to buy bitcoin” in Google).</p>',
  },

  steps: [
    {
      step: 1,
      actions: {
        timeOut: {
          finished: { won: true, karma: 0.1, info: '<p>Suurin osa nettikiristyksistä kokeilee vain kepillä jäätä. Varsinkin englanninkieliset viestit, joissa puhutaan käyttäjän nettikäyttäytymisen vakoilusta, ovat lähes poikkeuksetta keksittyjä.</p><p>Tällaisiin viesteihin ei yleensä kannata vastata mitään, edes lähteä vitsailemaan lähettäjän kanssa, koska silloin osoittaa ainoastaan olevansa aktiivinnen ja saattaa päätyä entistä useamman huijausyrityksen kohteeksi. Jos spämmiä haluaa välttää, paras tapa on merkitä huijausviestit roskapostiksi ja jättää reagoimatta.</p>' },
        },

        actionLeft: {
          id: 'blackmail-bad',
          title: 'Vastaa ettet maksa',
          finished: { won: false, karma: -0.1, info: '<p>Suurin osa nettikiristyksistä kokeilee vain kepillä jäätä. Varsinkin englanninkieliset viestit, joissa puhutaan käyttäjän nettikäyttäytymisen vakoilusta, ovat lähes poikkeuksetta keksittyjä.</p><p>Tällaisiin viesteihin ei yleensä kannata vastata mitään, edes lähteä vitsailemaan lähettäjän kanssa, koska silloin osoittaa ainoastaan olevansa aktiivinnen ja saattaa päätyä entistä useamman huijausyrityksen kohteeksi. Jos spämmiä haluaa välttää, paras tapa on merkitä huijausviestit roskapostiksi ja jättää reagoimatta.</p>' },
        },
        actionRight: {
          id: 'blackmail-good',
          title: 'Vastaa maksavasi lunnaat',
          finished: { won: false, karma: -0.15, info: '<p>Suurin osa nettikiristyksistä kokeilee vain kepillä jäätä. Varsinkin englanninkieliset viestit, joissa puhutaan käyttäjän nettikäyttäytymisen vakoilusta, ovat lähes poikkeuksetta keksittyjä.</p><p>Tällaisiin viesteihin ei yleensä kannata vastata mitään, edes lähteä vitsailemaan lähettäjän kanssa, koska silloin osoittaa ainoastaan olevansa aktiivinnen ja saattaa päätyä entistä useamman huijausyrityksen kohteeksi. Jos spämmiä haluaa välttää, paras tapa on merkitä huijausviestit roskapostiksi ja jättää reagoimatta.</p>' },
        },
      },
    },
  ]
}
