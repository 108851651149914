export default function analytics(eventCategory, eventAction, eventLabel) {
  const ga = {
    hitType: 'event',
    eventCategory,
    eventAction,
    eventLabel,
    transport: 'beacon',
  }
  window.ga('send', ga)
}
