import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
import * as serviceWorker from './serviceWorker';

import { setState, stateSubscribe } from './lib/state'
import getInitialState from './initial-state'

import { startStory } from './actions/storyActions'

const root = document.getElementById('root')

stateSubscribe(state => {
  ReactDOM.render(
    <App {...state} />,
    root
  )
})


window.startGame = function() {
  setState(getInitialState())

  // startStory('backup')
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();



const TERMS_MODALS = {
  about: document.getElementById('about'),
  privacy: document.getElementById('privacy'),
}
window.toggleTermsModal = (modal) => {
  TERMS_MODALS['about'].style.display = 'none'
  TERMS_MODALS['privacy'].style.display = 'none'
  if (modal) {
    TERMS_MODALS[modal].style.display = 'flex'
  }
}