export default {
  hidden: true,

  storyId: 'fakeprofile',
  currentStep: 1,
  initialStep: 1,

  pushMessage: {
    message: '[name] on kyllä nolo. Kato miten nolo kuva',
    type: 'facebook',
    pushCondition: 'home',
  },

  steps: [
    {
      step: 1,
      actions: {
        actionLeft: {
          id: 'fakeprofile-good',
          title: 'Anna olla, älä viitsi kiusata',
          finished: { won: true, karma: 0.1 },
        },
        actionRight: {
          id: 'fakeprofile-bad',
          title: 'Hitto, mikä dorka! (jaa kuva eteenpäin)',
          finished: { won: true, karma: -0.2 },
        },
      },
    },
  ]
}
