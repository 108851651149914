import React, { Component } from 'react';

import { doAction } from '../../actions/storyActions'

class Actions extends Component {
  componentDidMount() {
    if (this.props.notimer) {
      return
    }
    this.timeout = setTimeout(() => {
      if (this.props.timeOut) {
        doAction(this.props.timeOut)
      }
    }, 10500)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { title, subtitle, notimer, icon, feedbackText, actionLeft, actionRight, actionLeft2, actionRight2, smallOverlay } = this.props

    const timer = notimer ? '' : <div className="actions-time-left" dangerouslySetInnerHTML={{ __html: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewbox="0 0 100 100">
      <path d="M 25, 50
      a 25,25 0 1,1 50,0
      a 25,25 0 1,1 -50,0"
            />
    </svg>`}} />

    return <div className={"actions flex flex-1 flex-col max-w-full " + (smallOverlay ? "actions-small" : "")}>
      {feedbackText
        ? <div className="finished-info actions-window relative flex flex-col flex-1 justify-end p-6">
            <p>{feedbackText}</p>
          </div>
        : smallOverlay ? <div>
          {timer}
        </div> : <div className="actions-window relative flex flex-col flex-1 justify-end p-6">
            <div className="actions-icon flex items-center justify-center">
              {timer}
            </div>
            {title ? <h1>{title}</h1> : ''}
            {subtitle ? <p>{subtitle}</p> : ''}
          </div>
      }

      <div className="actions-buttons bg-offWhite">
        {actionLeft ?
          <div className="action action-left" onClick={() => doAction(actionLeft)}>
            <div className="action-arrow"><img src="/media/icons/Path 24@2x.png" /></div>
            {actionLeft.title}
          </div>
        : ''}
        {actionRight ?
          <div className="action action-right" onClick={() => doAction(actionRight)}>
            <div className="action-arrow"><img src="/media/icons/Path 24@2x.png" /></div>
            {actionRight.title}
          </div>
        : ''}
        {actionLeft2 ?
          <div className="action action-left" onClick={() => doAction(actionLeft2)}>
            <div className="action-arrow"><img src="/media/icons/Path 24@2x.png" /></div>
            {actionLeft2.title}
          </div>
        : ''}
        {actionRight2 ?
          <div className="action action-right" onClick={() => doAction(actionRight2)}>
            <div className="action-arrow"><img src="/media/icons/Path 24@2x.png" /></div>
            {actionRight2.title}
          </div>
        : ''}
      </div>
    </div>
  }
}

export default Actions