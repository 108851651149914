import React, { Component } from 'react';
import _ from 'lodash'

import { advanceStory } from '../../lib/storyController'
import { getMediaCache } from '../../lib/preloadMedia'
import { doAction } from '../../actions/storyActions'
import Actions from './Actions.jsx'
import Loading from '../Loading.jsx'

export default function({ title, currentStep, quiz, totalScore }) {
  const step = quiz[currentStep]

  if (!step) {
    console.error('step not found', currentStep)
    return null
  }

  const { questionTitle, choices, showResults } = step

  return <div className="il-page">
    <div className="flex flex-row il-header">
      <div className="flex-1"><img src="/media/icons/il/il.png" className="il-logo" /></div>
      <div className=""><img src="/media/icons/il/il2.png" className="il-menu" /></div>
    </div>

    <p>Tekstiviestinä saapuvassa viestissä voidaan väittää, että vastaanottaja on saanut esimerkiksi ääniviestin, joka pyydetään kuuntelemaan viestissä olevan linkin kautta.</p>

    <div className="break">Turvallisesti verkossa -testi</div>

    <div className="quiz">
      <div className="quiz-header">
        <h2>{title}</h2>
      </div>

      <div className="quiz-content flex flex-col">
        <div className="flex-1 mb-md">
          <p>{questionTitle}</p>
          {showResults ? <p>Sait {totalScore}/6 oikein</p> : ''}
        </div>


        {choices.map(choice => {
          return <div
            key={choice.id}
            onClick={() => doAction(choice)}
            className="quiz-button rounded text-white"
          >{choice.title}</div>
        })}
      </div>
    </div>

    <div className="break">Artikkeli jatkuu alla</div>

    <p>Tätä linkkiä ei tule avata, sillä se johtaa huijaussivustolle, jonka yläkulmaan on sijoitettu logo, joka muuttuu vierailevan liittymän operaattorin perusteella.</p>
    <p>Huijaussivuston kautta yritetään saada lataamaan sovellukseksi naamioitu Flubot-haittaohjelma, joka varastaa tietoja käyttäjän puhelimesta.</p>
    <p>112-sovelluksen kautta välitetyssä viestissä kehotetaan olemaan yhteydessä Kyberturvallisuuskeskukseen, mikäli on asentanut haittaohjelman laitteelleen.</p>
    <p>Väyrynen kertoo, että yhteydenottoja keskukselle on tullut hieman yli 200.</p>
    <p>– Yhteydenotot ovat olleet lähinnä tiedoksi Kyberturvallisuuskeskukselle -luonteisia.</p>
    <p>Kyberturvallisuuskeskus on jakanut aiemmin ohjeet tilanteisiin, joissa Flubot-haittaohjelman on asentanut laitteelle.</p>
  </div>
}