export default {
  hidden: true,

  storyId: 'phishing',
  currentStep: 1,
  initialStep: 1,

  pushMessage: {
    message: 'Seuraa paketin saapumista: https://postl.fi',
    from: '+353 85 387 5401',
    type: 'sms',
    pushCondition: 'finished',
  },

  steps: [
    {
      step: 1,
      actions: {
        timeOut: {
          finished: { won: true, karma: 0.1 },
        },
        actionRight: {
          id: 'phishing-bad',
          title: 'Seuraa linkkiä',
          finished: { won: false, karma: -0.25, info: 'Nettihuijausten määrä kasvaa jatkuvasti. Jos et ole tilannut mitään, älä avaa tuntemattomia linkkejä. Ja vaikka olisitkin tilannut jotakin, kannattaa aina tarkistaa viestin lähettäjä ja linkin muotoilu. Esimerkiksi https://postl.fi ei ole sama kuin https://posti.fi, eikä tulli ikinä lähetä tekstiviestejä ulkomaisista numeroista.' },
        },
      },
    },
  ]
}
