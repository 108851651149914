import React, { Component } from 'react';
import { getAllAchievements } from '../lib/achievements'

import Header from './Header.jsx'
import StoryCard from './StoryCard.jsx'
import Achievements from './Achievements.jsx'
import Share from './Share.jsx'
import { getNextStory, startStory, viewStoryInfo } from '../actions/storyActions'
import { navigate } from '../actions/navigate'

function getHighlightStory(visibleStories) {
  const highlightStory = getNextStory()

  if (!highlightStory) {
    return visibleStories[0]
  }

  return highlightStory
}

class Home extends Component {
  componentDidMount() {
  }


  render() {
    const { stories } = this.props

    const visibleStories = stories.filter(story => !story.hidden)
    const highlightStory = getHighlightStory(visibleStories)
    const restStories = visibleStories.filter(story => story.storyId !== highlightStory.storyId)

    const allAchievements = getAllAchievements(stories)

    return <div className="p-md home bg-dark">
      <Header {...this.props} />

      <h1 className="mb-6">Suosittelemme</h1>

      <div className="flex flex-row highlighted-story mb-12">
        <div className="flex-1 mr-md">
          <StoryCard {...highlightStory} imageOnly="true" onClick={() => viewStoryInfo(highlightStory.storyId)} />
        </div>

        <div className="flex flex-col flex-1">
          <Achievements {...highlightStory} onClick={() => viewStoryInfo(highlightStory.storyId)} />

          <h2 className="font-bold my-6" onClick={() => viewStoryInfo(highlightStory.storyId)} >{highlightStory.title}</h2>
          <p className="mb-6">{highlightStory.description}</p>

          <div className="button button-primary" onClick={() => startStory(highlightStory.storyId, true)}>
            Pelaa
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="mb-3">Kaikki tarinat</h2>
        <Achievements achievements={allAchievements} onClick={() => navigate('achievements')} />
      </div>

      <div className="flex flex-row flex-wrap -mr-md">
        {restStories.map(story => {
          if (story.hidden) {
            return null
          }
          return <div key={story.storyId} className="flex-1 pr-md mb-md half-width">
            <StoryCard {...story} onClick={() => viewStoryInfo(story.storyId)} />
          </div>
        })}
      </div>

      <Share />
    </div>
  }
}

export default Home;
