import React, { Component } from 'react';
import { getAllAchievements } from '../lib/achievements'

import AchievementIcon from './AchievementIcon.jsx'
import Header from './Header.jsx'

class AchievementsPage extends Component {
  render() {
    const achievements = getAllAchievements(this.props.stories)

    return <div className="p-6 home bg-dark">
      <Header {...this.props} />

      <h1>Kaikki saavutukset</h1>

      <div className="flex flex-row flex-wrap -mr-md">
        {achievements.map(achievement => {
          const { title } = achievement
          return <AchievementIcon {...achievement} key={title} />
        })}
      </div>
    </div>
  }
}

export default AchievementsPage;
