const cache = {}

export async function setMediaCache(source) {
  const media = await preloadMedia(source)

  cache[source] = media

  return media
}

export function getMediaCache(source) {
  return cache[source]
}

export default function preloadMedia(source) {
  return new Promise((resolve, reject) => {
    // console.info('preload', source)

    const req = new XMLHttpRequest();
    req.open('GET', source, true);
    req.responseType = 'blob';

    req.onload = function() {
      // console.info('loaded', this.status, source)
       if (this.status === 200) {
          var blob = this.response;
          var media = URL.createObjectURL(blob); // IE10+
          // Video is now downloaded
          resolve(media)
       }
    }
    req.onerror = function() {
      console.error('error loading', source)
       // Error
       reject()
    }

    req.send();
  })
}