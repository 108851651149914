// todo email-tyyppisempi näkymä

export default {
  hidden: true,

  storyId: 'authenticator',
  currentStep: 1,
  initialStep: 1,

  pushMessage: {
    message: 'Joku taisi kaapata mun YouTube-tilin. What do?',
    type: 'messenger',
    pushCondition: 'finished',
  },

  steps: [
    {
      step: 1,
      actions: {
        actionLeft: {
          id: 'authenticator-good',
          title: 'Tiedän mikä on Google Authenticator',
          finished: { won: true, karma: 0.1 },
        },
        actionRight: {
          id: 'authenticator-info',
          title: 'En tiedä mikä on Google Authenticator',
          continueToStep: 2,
        },
      },
    },
    {
      step: 2,
      videos: ['/media/video/s2/authenticator.mp4'],
      waitForClick: true,
      videoEndAction: {
        finished: { won: true, karma: 0.15, info: '<p>2FA eli kaksivaiheinen tunnistautuminen tarkoittaa sitä, että kukaan ei voi murtautua tilillesi pelkästään keksimällä salasanasi. Tilille voi kirjautua uudella laitteella ainoastaan, jos kirjautuminen vahvistetaan toisella jo aiemmin tunnistetulla laitteella.</p><p>Kaksivaiheiseen tunnistautumiseen on olemassa monia ilmaisia palveluita. Suurin osa meistä käyttää laajasti Googlea (esimerkiksi gmail tai youtube), joten ainakin Google Authenticatoriin kannattaa tutustua.</p>' },
      },
    },
  ]
}
