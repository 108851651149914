import React, { Component } from 'react';
import { isAchieved } from '../lib/achievements'

class AchievementIcon extends Component {
  state = {
    touched: false,
  }

  render() {
    const { title, image, description } = this.props
    const achieved = isAchieved(this.props)

    const { touched } = this.state

    return <div  className="flex-1 py-md pr-md half-width" onClick={() => this.setState({ touched: !touched })}>
      <div className="achievement pointer flex flex-col items-center p-6 rounded-lg bg-black relative">
        <img className="max-w-1/2 mb-6" src={achieved ? image : '/media/images/achievements/notunlocked.png'} />
        <div className="font-bold">{achieved ? title : '???'}</div>
        {achieved ? <div
          className="bg-gray rounded-lg flex-col justify-center text-center achievement-description"
          style={{ display: touched ? 'flex' : 'none' }}
        >
          <div className="font-bold mb-6">{title}</div>
          <div>{description}</div>
        </div>: ''}
      </div>
    </div>
  }
}

export default AchievementIcon;
