import { getLocalStorage } from './localStorage'

export function isAchieved(achievement) {
  if (!achievement) {
    return
  }

  const { savedActions = [], completedStories = [] } = getLocalStorage()

  if (achievement.id) {
    return savedActions.includes(achievement.id)
  } else if (achievement.ids) {
    return achievement.ids.every(id => savedActions.includes(id))
  } else if (achievement.completedStoriesLength) {
    return completedStories.length >= achievement.completedStoriesLength
  }
}

const globalAchievements = [
  {
    completedStoriesLength: 7,
    title: 'The end?',
    image: '/media/images/achievements/theend.png',
    description: 'Läpäise season 1'
  },
  {
    ids: ['wifi-vpn', 'wifi-hacked-friend', 'wifi-hacked-internet', 'mobilepayment-ask-server', 'mobilepayment-ask-bank', 'mobilepayment-near-payment', 'mobilepayment-pin', 'copyright-delete', 'copyright-money', 'password-weak', 'password-strong', 'ransomware-trap', 'ransomware-no-3', 'backup-delete', 'backup-cloud', 'backup-computer'],
    title: 'No path untrodden',
    image: '/media/images/achievements/nopathuntrodden.png',
    description: 'Pelaa kaikki vaihtoehdot läpi'
  },
]

export function getAllAchievements(stories) {
  let achievements = []

  stories.forEach(story => {
    if (story.achievements) {
      achievements = achievements.concat(story.achievements)
    }
  })

  achievements = achievements.concat(globalAchievements)

  return achievements
}
