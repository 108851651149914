const finishedInfo = {
  finished: {
    won: true, karma: 0.2,
    title: 'Omistatko ottamasi kuvan?',
    info: '<p>Kun julkaiset kuvan internetissä, pidät kaikki tekijänoikeudet aina itselläsi. Jotkut sosiaalisen median palvelut pyytävät lupaa kuviesi jakoon niiden sopimusehdoissa, eli lue ”Terms & Conditions” ennen kuin otat palvelun käyttöön. Vesileiman lisääminen kuviisi on hyvä tapa varmistaa, ettei kukaan ulkopuolinen taho varasta niitä. Toinen tapa on julkaista kuva pienessä resoluutiossa niin, että sen kaupallinen käyttö olisi käytännössä mahdotonta. Lue lisää tekijänoikeuksista: <a href="kuvasto.fi" target="_blank">kuvasto.fi</a></p>',
  },
}

export default {
  storyId: 'copyright',
  currentStep: 1,
  initialStep: 1,

  title: 'Tekijänoikeudet',
  description: 'Kaikki tykkäävät jakaa kuvia internetissä. Mitä kuvillesi tapahtuu, kun sinä jaat ne?',
  image: '/media/images/stories/kyber_game_3.jpg',

  achievements: [
    { id: "copyright-share", title: 'Show what you got', image: '/media/images/achievements/showwhatyougot.png', description: 'Jaa kuva somessa' },
  ],

  steps: [
    {
      step: 1,
      videos: ['/media/video/copyright/taking-photo.mp4'],
      actions: {
        title: 'Miten toimit?',
        timeOut: {
          finished: { won: false, karma: -0.1 },
        },
        actionLeft: {
          id: 'copyright-no',
          title: 'En jaa kuvaa',
          finished: { won: true, karma: 0, title: 'Kaikkea ei kannata jakaa', info: 'Kannattaa miettiä mitä verkkoon jakaa. Yleensä kuvien jakaminen on aivan harmitonta, mutta älä jaa arkaluontoisia kuvia joissa näkyy esimerkiksi salasanoja tai tunnuslukuja myöskään kuvaa kaverista ei ole hyvä jakaa ilman lupaa. Jos et halua, että kuviasi käytetään ilman lupaa, yksi hyvä keino on käyttää vesileimoja kuvissa.' },
        },
        actionRight: {
          id: 'copyright-share',
          title: 'Jaan kuvan',
          continueToStep: 2,
        },
      },
    },
    {
      step: 2,
      // videos: ['/media/video/copyright/kuvavaras-chat.mp4'],
      actions: {
        title: 'Huomaat, että startup käytti kuvaasi ilman lupaa. Miten toimit?',
        timeOut: {
          finished: { won: false, karma: -0.1 },
        },
        actionLeft: {
          id: 'copyright-dont-care',
          title: 'Ihan sama...',
          finished: { won: false, karma: 0 },
          continueToNextStory: true,
        },
        actionRight: {
          id: 'copyright-contact',
          title: 'Otan yhteyttä',
          continueToStep: 3,
        },
      },
      preloadStep: 3,
    },
    {
      step: 3,
      videos: ['/media/video/copyright/kuvavaras-chat.mp4'],
      actions: {
        title: 'Miten toimit?',
        timeOut: {
          finished: { won: false, karma: -0.1 },
        },
        actionLeft: {
          id: 'copyright-delete',
          title: 'Pyydän poistamaan kuvan',
          continueToStep: 4,
        },
        actionRight: {
          id: 'copyright-money',
          title: 'Pyydän korvausta kuvan käytöstä',
          continueToStep: 5,
        },
      },
    },
    {
      step: 4,
      videos: ['/media/video/copyright/kuvavaras-poista-chat.mp4'],
      videoEndAction: finishedInfo,
    },
    {
      step: 5,
      videos: ['/media/video/copyright/kuvavaras-maksu-chat.mp4'],
      videoEndAction: finishedInfo,
    },
  ]
}
