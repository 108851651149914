export default {
  storyId: 'backup',
  currentStep: 1,
  initialStep: 1,

  title: 'Varmuuskopiointi',
  description: 'Tärkeitä tiedostoja voi säilyttää monella eri tavalla. Mikä on sinun tapasi?',
  image: '/media/images/stories/kyber_game_4.jpg',

  achievements: [
  ],

  steps: [
    {
      step: 1,
      videos: ['/media/video/backup/tila-lopussa-notif.mp4'],
      waitForClick: true,
      actions: {
        title: 'Miten toimit?',
        actionLeft: {
          id: 'backup-delete',
          title: 'Älä tee mitään',
          // continueToStep: 10,
          continueToStep: 11,
        },
        actionRight: {
          id: 'backup-backup',
          title: 'Varmuuskopioin',
          continueToStep: 20,
        },
      },
    },
    // {
    //   step: 10,
    //   // videos: ['/media/video/backup/food-arrives-1.mp4'],
    //   continueToStep: 11,
    // },
    {
      step: 11,
      videos: ['/media/video/backup/phone-damage-1.mp4', '/media/video/backup/phone-damage-2.mp4'],
      continueToStep: 12,
    },
    {
      step: 12,
      videos: ['/media/video/backup/phone-damaged.mp4'],
      continueToStep: 13,
    },
    {
      step: 13,
      videos: ['/media/video/backup/phone-fix.mp4', '/media/video/backup/phone-fix-1.mp4'],
      videoEndAction: {
        finished: {
          won: false, karma: -0.45,
          title: 'Menetit tiedostosi',
        },
      }
    },
    {
      step: 20,
      actions: {
        title: 'Miten haluat varmuuskopioida?',
        actionLeft: {
          id: 'backup-cloud',
          title: 'Pilvipalveluun',
          continueToStep: 21,
        },
        actionRight: {
          id: 'backup-computer',
          title: 'Tietokoneelle',
          continueToStep: 22,
        },
      },
    },
    {
      step: 21,
      videos: ['/media/video/backup/varmuus-pilvi.mp4'],
      waitForClick: true,
      videoEndAction: {
        finished: {
          won: true, karma: 0.3,
          title: "Miten varmuuskopiointi pilvessä toimii?",
          info: "<p>Tiedostojen tallentaminen pilveen on usein helppo, nopea ja turvallinen tapa tallentaa sinulle tärkeitä tiedostoja. Vaihtoehtoja on monia, kuten Dropbox, Google Drive tai iCloud. Moni palvelu tarjoaa tilaa ilmaiseksi, mutta usein pientä lisämaksua vastaan on mahdollista saada enemmän tallennustilaa.</p>",
        },
      }
    },
    {
      step: 22,
      videos: ['/media/video/backup/varmuus-kone.mp4'],
      waitForClick: true,
      continueToStep: 30,
    },
    {
      step: 30,
      videos: ['/media/video/backup/phone-damage-1.mp4', '/media/video/backup/phone-damage-2.mp4'],
      continueToStep: 31,
    },
    {
      step: 31,
      videos: ['/media/video/backup/phone-damaged.mp4'],
      continueToStep: 32,
    },
    {
      step: 32,
      videos: ['/media/video/backup/phone-fix.mp4', '/media/video/backup/phone-fix-1.mp4'],
      videoEndAction: {
        finished: {
          won: true, karma: 0.2,
          title: 'Onneksi teit varmuuskopiot',
          info: '<p>Tietokoneelle tai ulkoiselle kovalevylle tehdyt varmuuskopiot ovat ihan hyvä ratkaisu, mutta kannattaa harkita pilvipalvelun käyttämistä. Tarjolla on useita ilmaisia pilvipalveluja, joiden etu on siinä, että ne synkronoivat tiedostosi talteen reaaliajassa. Et siis menetä viimeisimmän varmuuskopioinnin jälkeisiä tiedostojasi vaikka puhelin menisi rikki.</p>',
        },
      },
    },
  ]
}
