export default {
  hidden: true,

  storyId: 'school',
  currentStep: 1,
  initialStep: 1,

  pushMessage: {
    message: 'Toi [name] on aika laiska ja saamaton tapaus. Voisitko vähän potkia sitä eteenpäin?',
    type: 'sms',
    pushCondition: 'home',
  },

  steps: [
    {
      step: 1,
      actions: {
        actionLeft: {
          id: 'school-bad',
          title: 'Älä tee mitään',
          finished: { won: true, karma: -0.1 },
        },
        actionRight: {
          id: 'school-good',
          title: 'Ehkä sun pitäis puhua hänelle suoraan, ei mulle?',
          finished: { won: true, karma: 0.15 },
        },
      },
    },
  ]
}
