import React, { Component } from 'react';
import _ from 'lodash'

import { advanceStory } from '../../lib/storyController'
import { getMediaCache } from '../../lib/preloadMedia'
import { doAction } from '../../actions/storyActions'
import Actions from './Actions.jsx'
import Loading from '../Loading.jsx'

// not really just video
class Video extends Component {
  state = {
    videoLoaded: false,
    clicked: false,
    showActions: this.props.videos && this.props.videos.length ? false : true,
  }

  videoLoaded() {
    this.setState({ videoLoaded: true })
  }

  videoEnded() {
    if (this.props.waitForClick && !this.state.clicked) {
      this.setState({ waitingForClick: true })
      return
    }

    if (this.props.actions) {
      this.setState({
        showActions: true,
      })
      return
    }

    if (this.props.continueToStep) {
      advanceStory(this.props.continueToStep)
    }

    if (this.props.videoEndAction) {
      doAction(this.props.videoEndAction)
    }
  }

  onClick() {
    this.setState({ clicked: true }, () => {
      if (this.state.waitingForClick) {
        this.videoEnded()
      }
    })
  }

  skipVideo() {
    // no need to wait for click if skipped
    this.setState({ clicked: true })

    this.videoRef.pause()
    this.videoEnded()
  }

  render() {
    const { videos, videoIndex, actions } = this.props
    const { videoLoaded, showActions } = this.state
    const video = videos && videos[videoIndex]

    const cached = getMediaCache(video)

    const src = cached || video

    let bg = ''
    if (!src) {
      bg = 'h-full home-bg-' + _.random(1, 4)
    }

    return <div className={"video " + bg} onClick={() => this.onClick()}>
      {src && !videoLoaded ? <Loading
        key={'loading-' + video}
      /> : ''}
      {src ? <video
        ref={ref => this.videoRef = ref}
        autoPlay
        onLoadedData={() => this.videoLoaded()}
        onEnded={() => this.videoEnded()}
        key={video}
        disableremoteplayback="true"
        disablePictureInPicture={true}
        playsInline={true}
      >
        <source
          src={src}
          type="video/mp4"
        />
      </video> : ''}

      {showActions ? <div className="video-overlay absolute flex">
        <Actions {...actions} />
      </div> : <div className="skip-video" onClick={() => this.skipVideo()}>
        Ohita video >
      </div>}
    </div>
  }
}

export default Video;
