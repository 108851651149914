// todo email-tyyppisempi näkymä

export default {
  hidden: true,

  storyId: 'fakemail',
  currentStep: 1,
  initialStep: 1,

  pushMessage: {
    message: 'Äiti jakoi Google Drive kansion kanssasi ”Voita 100BTC!!!”',
    type: 'email',
    pushCondition: 'finished',
  },

  steps: [
    {
      step: 1,
      actions: {
        actionLeft: {
          id: 'fakemail-bad',
          title: 'Avaa viesti',
          doClosePushMessage: true,
          continueToStep: 2,
        },
        actionRight: {
          id: 'fakemail-good',
          title: 'Älä avaa, pyydä äitiä vaihtamaan Googlen salasana',
          finished: { won: true, karma: 0.15 },
        },
      },
    },
    {
      step: 2,
      videos: ['/media/video/wifi/hakkeroitu-notif.mp4'],
      waitForClick: true,
      videoEndAction: {
        finished: { won: false, karma: -0.2, title: 'Puhelimesi hakkeroitiin' },
      },
    },
  ]
}
