import React, { Component } from 'react';
import _ from 'lodash'

import Quiz from './Quiz.jsx'
import News from './News.jsx'
import Email from './Email.jsx'
import Video from './Video.jsx'
import Share from '../Share.jsx'
import KarmaBar from './KarmaBar.jsx'

import { navigate } from '../../actions/navigate.js'
import { getNextStory, startStory } from '../../actions/storyActions.js'

export default function({ story, ...props }) {
  const { currentStep, steps, finished, quiz, news, email, totalScore } = story

  if (finished) {
    const nextStory = getNextStory()

    return <div className="finished-screen bg-black flex flex-1 flex-col pt-lg pb-md px-6">
      <div className="mb-lg">
        {nextStory ? <span className="button-small" onClick={() => navigate(false, { story: false }, !!story.pushMessage)}>Päävalikko</span> : ''}
      </div>

      {finished.won ? <div className="flex flex-col flex-1">
        <div className="finished-info mb-lg">
          <div className="finished-info-content">
            {finished.title ? <h1 className="finished-info-title">{finished.title}</h1> : ''}

            {finished.karma !== undefined ? <KarmaBar  {...props} /> : ''}

            {finished.info ? <div className="finished-info" dangerouslySetInnerHTML={{ __html: finished.info }} /> : ''}
          </div>
        </div>

        {nextStory
          ? <div className="button" onClick={() => startStory(nextStory.storyId, true)}>Jatka</div>
          : <div className="button" onClick={() => navigate(false, { story: false }, !!story.pushMessage)}>Päävalikko</div>
        }
      </div> : <div className="flex flex-col flex-1">
        <div className="finished-info mb-lg">
          <div className="finished-info-content">
            <img className="icon mx-auto mb-md" src="/media/icons/Group 26@2x.png" />
            <h1 className="finished-info-title text-center">Peli ohi.</h1>
            {finished.title ? <h2 className="text-red text-center mb-md">{finished.title}</h2> : ''}
            {finished.karma ? <KarmaBar  {...props} /> : ''}
            {finished.info ? <div dangerouslySetInnerHTML={{ __html: finished.info }} /> : ''}
          </div>
        </div>

        <div className="button" onClick={() => startStory(story.storyId)}>Aloita alusta</div>
      </div>}

      <Share />
    </div>
  }

  if (quiz) {
    return <div className="story">
      <Quiz key={currentStep} {...story} />
    </div>
  }

  const step = _.find(steps, { step: currentStep })

  if (!step) {
    console.error('step not found', currentStep)
    return null
  }

  if (news) {
    return <div className="story">
      <News {...story} {...step} />
    </div>
  }

  if (email) {
    return <div className="story">
      <Email {...story} {...step} />
    </div>
  }

  return <div className="story">
    <Video key={currentStep} {...step} />
  </div>
}