export default {
  hidden: true,

  storyId: 'tutorial',
  currentStep: 1,
  initialStep: 1,

  // title: 'tutorial',
  // description: 'lorem ipsum dolor',
  // image: '/media/images/stories/kyber_game_1.jpg',

  achievements: [
    { id: "tutorial-completed", title: 'Hello world', image: '/media/images/achievements/helloworld.png', description: 'Tutorialin läpi pääseminen' },
  ],

  steps: [
    {
      step: 1,
      videos: ['/media/video/tutorial/unboxing-4.mp4'],
      actions: {
        notimer: true,
        subtitle: 'Sinun on reagoitava eri pelitilanteisiin valitsemalla vaihtoehdoista mielestäsi oikea vastaus. Voit esimerkiksi swaipata oikealle tai vasemmalle. Oikeilla vastauksilla peli on mahdollista pelata nopeastikin läpi. Jos kuitenkin tuntuu, että pelasit pelin läpi todella nopeasti, testaa mitä tapahtuu jos muutat vastauksiasi. Älä kuitenkaan mieti vastauksiasi liian pitkään, sillä kello tikittää. Kokeile!',
        actionLeft: {
          id: 'tutorial-completed',
          title: 'Ymmärretty!',
          finished: { won: true, karma: 0.1, title: 'Peli alkaa' },
        },
        actionRight: {
          id: 'tutorial-completed',
          title: 'Let’s go!',
          finished: { won: true, karma: 0.1, title: 'Peli alkaa' },
        },
      },
    },
  ]
}
