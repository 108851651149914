const finished = {
  title: '',
  won: true,
}

export default {
  storyId: 'cyberquiz',
  currentStep: 0,
  initialStep: 0,

  pushMessage: {
    message: 'Moi, tee tää testi',
    type: 'messenger',
    pushCondition: 'finished',
    closeOnClick: true,
  },

  hidden: true,
  isNew: true,

  title: 'Tiedätkö miten toimia turvallisesti verkossa?',
  description: 'Testaa taitosi Iltalehden testissä!',
  // image: '/media/images/stories/kyber_game_2.jpg',

  achievements: [
    // { id: "wifi-cafe", title: 'Cup o’ joe.', image: '/media/images/achievements/cupojoe.png', description: 'Valitse ”lataa kahvilassa” vaihtoehto' },
    // { id: "wifi-vpn", title: 'Better safe', image: '/media/images/achievements/bettersafe.png', description: 'Asensit VPN:n pelissä. Hyvä. Tee sama seuraavasti oikeasti.' },
  ],

  quiz: [
    {
      questionTitle: 'Testaa taitosi Iltalehden testissä!',
      choices: [
        { title: 'Aloita testi', id: 'cyberquiz-start',  continueToStep: 1, resetScore: true },
      ]
    },
    {
      questionTitle: 'Mitä ”https://” tarkoittaa nettiosoitteen edessä? (Toisin kuin ”http://”)',
      choices: [
        { title: 'Nettisivu päivitetään usein', id: 'cyberquiz-1a',  continueToStep: 2, score: 0 },
        { title: 'Nettisivulle pääsee vain tietystä maasta', id: 'cyberquiz-1b',  continueToStep: 2, score: 0 },
        { title: 'Nettisivulle syötetty tieto on suojattu', id: 'cyberquiz-1c',  continueToStep: 2, score: 1 },
      ]
    },
    {
      questionTitle: '”Yksityistila” (private/incognito) selaimessa estää..?',
      choices: [
        { title: '… nettipalvelun tarjoajaa näkemästä millä sivuilla käyt', id: 'cyberquiz-2a',  continueToStep: 3, score: 0 },
        { title: '… vaikeuttaa huomattavasti hakkereiden pääsyä tietoihisi', id: 'cyberquiz-2b',  continueToStep: 3, score: 0 },
        { title: '… selaimen tallentamasta tiettyjä evästeitä koneellesi', id: 'cyberquiz-2c',  continueToStep: 3, score: 1 },
      ]
    },
    {
      questionTitle: '”Lukko”-ikoni selaimen osoiterivissä, ennen nettisivun osoitetta tarkoittaa mitä?',
      choices: [
        { title: 'Nettisivu on hyvin suojattu', id: 'cyberquiz-3a',  continueToStep: 4, score: 1 },
        { title: 'Nettisivu on lukittu ja siihen ei pääse', id: 'cyberquiz-3b',  continueToStep: 4, score: 0 },
        { title: 'Nettisivulla on tapahtunut hyökkäys ja se ei ole turvallinen', id: 'cyberquiz-3c',  continueToStep: 4, score: 0 },
      ]
    },
    {
      questionTitle: 'Hakkerit järjestävät hyökkäyksiä käyttämällä monia toisiinsa yhdistettyjä laitteita. Miksi tätä kutsutaan?',
      choices: [
        { title: 'Botnet', id: 'cyberquiz-4a',  continueToStep: 5, score: 1 },
        { title: 'Rootnet', id: 'cyberquiz-4b',  continueToStep: 5, score: 0 },
        { title: 'DDoS', id: 'cyberquiz-4c',  continueToStep: 5, score: 0 },
      ]
    },
    {
      questionTitle: 'VPN (Virtual Private Network) auttaa turvallisuudessa kun…?',
      choices: [
        { title: 'Surffailet netissä avoimissa verkoissa (kahvilat, lentokentät yms.)', id: 'cyberquiz-5a',  continueToStep: 6, score: 1 },
        { title: 'Ostat netissä jotain käyttäen luottokorttiasi', id: 'cyberquiz-5b',  continueToStep: 6, score: 0 },
        { title: 'Lataat tiedostoja koneellesi', id: 'cyberquiz-5c',  continueToStep: 6, score: 0 },
      ]
    },
    {
      questionTitle: 'Mitä "varoituskolmio" - ikoni tarkoittaa selaimen osoiterivin alussa?',
      choices: [
        { title: 'Sivulla on aikuissisältöä', id: 'cyberquiz-6a', score: 0, continueToStep: 7 },
        { title: 'Sivu sisältää valheellista informaatiota', id: 'cyberquiz-6b', score: 0, continueToStep: 7 },
        { title: 'Sivun SSL-sertifikaatti on vanhentunut', id: 'cyberquiz-6c', score: 1, continueToStep: 7 },
      ]
    },
    {
      questionTitle: 'Tuloksesi',
      showResults: true,

      choices: [
        { title: 'Jatka', id: 'cyberquiz-end', finished },
        { title: 'Aloita alusta', id: 'cyberquiz-start', continueToStep: 0 },
      ]
    },
  ],
}
