import { setState } from '../lib/state'
import { maybeSchedulePushMessage } from './pushMessage'
import analytics from '../lib/analytics'

export function navigate(route, state = {}, noPushMessage) {
  if (route === false && !noPushMessage) {
    maybeSchedulePushMessage('home')
  }

  setState({ route, ...state })

  analytics('route', 'navigate', route)
}