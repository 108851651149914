import _ from 'lodash'
import { state, setState } from '../lib/state'
import { getLocalStorage, setLocalStorage } from '../lib/localStorage'

function randomName(excludeName) {
  const names = [
    'Aino',
    'Aleksi',
    'Amanda',
    'Anna',
    'Eetu',
    'Elias',
    'Elina',
    'Ella',
    'Emilia',
    'Emma',
    'Johanna',
    'Johannes',
    'Juhani',
    'Julia',
    'Katariina',
    'Kristian',
    'Leevi',
    'Maria',
    'Matias',
    'Mikael',
    'Olavi',
    'Olivia',
    'Oskari',
    'Sofia',
    'Valtteri',
    'Veeti',
    'Venla',
    'Ville',
  ]

  if (excludeName) {
    return _.sample(_.without(names, excludeName))
  }

  return _.sample(names)
}

let pushMessageTimeout

export function maybeSchedulePushMessage(context) {
  clearTimeout(pushMessageTimeout)
  pushMessageTimeout = setTimeout(() => maybePushMessage(context), _.random(1000, 3000))
}

export function maybePushMessage(context) {
  const storage = getLocalStorage()
  storage.completedStories = storage.completedStories || []

  const pushMessageStories = state.stories.filter(story => {
    const { storyId, pushMessage } = story

    // return storyId === 'blackmail'

    if (!storyId || !pushMessage) {
      return
    }

    if (storage.completedStories.includes(storyId)) {
      return
    }

    if (context !== pushMessage.pushCondition) {
      return
    }

    return true
  })

  if (process.env.NODE_ENV === 'development') {
    console.log('pushMessageStories', pushMessageStories)
  }

  if (pushMessageStories.length) {
    closePushMessage()

    const pushMessageStory = _.sample(pushMessageStories)

    const from = randomName()

    const pushMessage = pushMessageStory.pushMessage
    pushMessage.message = pushMessage.message.replace('[name]', randomName(from))

    setPushMessage({
      from,
      storyId: pushMessageStory.storyId,
      timeout: 5000,
      ...pushMessage,
    })
  }
}

export function setPushMessage(pushMessage) {
  setState({ pushMessage })

  saveMessage(pushMessage)
}

export function saveMessage(message) {
  const storage = getLocalStorage()

  storage.messages = [message].concat(storage.messages)

  setLocalStorage(storage)
}

export function closePushMessage(pushMessage) {
  clearTimeout(pushMessageTimeout)
  setState({ pushMessage: false })
}