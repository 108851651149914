export default {
  hidden: true,

  storyId: 'fakenews',
  currentStep: 1,
  initialStep: 1,

  pushMessage: {
    message: 'Tsekkaa tämä uutinen',
    type: 'messenger',
    pushCondition: 'finished',
    closeOnClick: true,
  },

  news: {
    title: 'Italia julistaa ananaksen lisäämisen pitsaan laittomaksi. Rikkeestä jopa viisi vuotta vankeutta.',
    description: 'Italian uudesta rajoitteesta tiedotettiin aikaisin aamulla Suomen aikaan. Useita suomalaisia turisteja on käännytetty ravintoloiden ovilla.',
    image: '/media/images/s2/pizza-ananas.jpg',
    content: '<p>Italia vaalii käsitystä, jonka mukaan maailman ensimmäiset pizzat valmistettiin Napolissa 1800-luvulla. Kansalaiset toivovat, että myös Yhdistyneiden kansakuntien alainen Unesco tunnustaa tämän ja antaa napolilaiselle pizzalle erityisaseman.</p><p>Perinteiseen napolilaiseen pizzaan tulisi käyttää durumvehnäjauhoja, merisuolaa, tuorehiivaa, tomaattikastiketta, puhvelinmaidosta valmistettua mozzarellaa ja basilikan lehtiä. Näin pizza myös tunnustaa Italiaa väreineen: punaista, valkoista ja vihreää kun löytyy myös maan lipusta.</p><p>Ensimmäinen tällainen pizza syntyi vuonna 1889, ja se sai nimekseen Margherita silloisen Italian kuninkaan vaimon mukaan.</p>',
  },

  steps: [
    {
      step: 1,
      actions: {
        notimer: true,
        actionLeft: {
          id: 'fakenews-bad',
          title: 'Tottakai jaan tämän',
          finished: { won: false, karma: -0.2 },
        },
        actionRight: {
          id: 'fakenews-good',
          title: 'Ei jatkoon ja kaveri mutelle',
          finished: { won: true, karma: 0.1 },
        },
      },
    },
  ]
}
