export default {
  storyId: 'mobilepayment',
  currentStep: 1,
  initialStep: 1,

  title: 'Mobiilimaksut',
  description: 'Nykyään on monia tapoja maksaa kaupoissa ja ravintoloissa. Mikä on sinun tyylisi?',
  image: '/media/images/stories/kyber_game_1.jpg',

  achievements: [
    { id: "mobilepayment-mobilepay", title: 'Bón Apetit', image: '/media/images/achievements/bonapetit.png', description: 'Maksa ravintolassa mobiililla' },
    { id: "mobilepayment-friend-in-need", title: 'A friend in need', image: '/media/images/achievements/afriendinneed.png', description: 'Lainaa kaverille 20€' },
  ],

  steps: [
    {
      step: 1,
      videos: ['/media/video/mobilepayment/food-arrives-1.mp4'],
      continueToStep: 2,
    },
    {
      step: 2,
      videos: ['/media/video/mobilepayment/payment-1.mp4'],
      actions: {
        title: 'Miten haluat maksaa?',
        timeOut: {
          finished: { won: false, karma: -0.1 },
        },
        actionLeft: {
          id: 'mobilepayment-mobilepay',
          title: 'Käytän mobiilimaksua',
          continueToStep: 10,
        },
        actionRight: {
          id: 'mobilepayment-card',
          title: 'Maksan kortilla',
          continueToStep: 20,
        },
      },
    },
    {
      step: 10,
      videos: ['/media/video/mobilepayment/payment-phone.mp4', '/media/video/mobilepayment/payment-phone.mp4'],
      actions: {
        title: 'Haluatko kuitin?',
        timeOut: {
          finished: {
            continueToStep: '10b',
          },
        },
        actionLeft: {
          id: 'mobilepayment-receipt-no',
          title: 'En',
          continueToStep: '10c',
        },
        actionRight: {
          id: 'mobilepayment-receipt-yes',
          title: 'Kyllä',
          continueToStep: '10b',
        },
      },
    },
    {
      step: '10b',
      videos: ['/media/video/mobilepayment/receipt.mp4', '/media/video/mobilepayment/receipt-2.mp4'],
      continueToStep: '10c',
    },
    {
      step: '10c',
      videos: ['/media/video/mobilepayment/kortilta-haviaa-rahaa-viesti.mp4'],
      actions: {
        title: 'Huomaat rahaa hävinneen tililtäsi',
        subtitle: 'Miten toimit?',
        timeOut: {
          finished: { won: false, karma: -0.1 },
        },
        actionLeft: {
          id: 'mobilepayment-ask-server',
          title: 'Kysy apua tarjoilijalta',
          continueToStep: 11,
        },
        actionRight: {
          id: 'mobilepayment-ask-bank',
          title: 'Ota yhteyttä pankkiin',
          continueToStep: 12,
        },
      },
    },
    {
      step: 11,
      videos: ['/media/video/mobilepayment/kysy-tarjoilijalta-apua.mp4'],
      videoEndAction: {
        finished: { won: false, karma: -0.35, title: 'Jos tililtäsi on kadonnut rahaa, ota yhteyttä pankkiin.' },
      },
    },
    {
      step: 12,
      videos: ['/media/video/mobilepayment/asiakaspalvelu.mp4'],
      videoEndAction: {
        finished: { won: true, karma: 0.1, title: 'Ovatko rahasi kadonneet?', info: '<p>Luottokorttirikokset ovat yleisempiä kuin moni luulee, mutta onneksi niiden selvitys ei vaadi paljoa. Rahat saa useassa tapauksessa takaisin, jos reagoi nopeasti. Sinun on tehtävä tapahtuneesta rikosilmoitus poliisille ja ilmoitus omalle luottokorttiyhtiöllesi. Muista ottaa aina kuitit talteen joko paperi- tai digimuodossa ja pidä huoli, etteivät muut saa korttitietojasi.</p>' },
      },
    },

    {
      step: 20,
      actions: {
        title: 'Mikä on tyylisi?',
        timeOut: {
          finished: { won: false, karma: -0.1 },
        },
        actionLeft: {
          id: 'mobilepayment-pin',
          title: 'Pin',
          continueToStep: '21a',
        },
        actionRight: {
          id: 'mobilepayment-near-payment',
          title: 'Lähimaksu',
          continueToStep: 22,
        },
      },
    },
    {
      step: '21a',
      actions: {
        feedbackText: 'Vaikka et käyttäisi lähimaksuominaisuutta, huomaathan että maksukortissasi tai älypuhelimessasi todennäköisesti on lähimaksuominaisuus. Kannattaa tarkistaa, ettei se ole turhan takia käytössä, jos et sitä käytä.',
        actionRight: {
          id: 'mobilepayment-pin-ok',
          title: 'Ok',
          continueToStep: '21b',
        },
      },
    },
    {
      step: '21b',
      videos: ['/media/video/mobilepayment/payment-card-insert.mp4'],
      actions: {
        title: 'Haluatko kuitin?',
        timeOut: {
          finished: {
            continueToStep: '10b',
          },
        },
        actionLeft: {
          id: 'mobilepayment-receipt-no',
          title: 'En',
          continueToStep: '10c',
        },
        actionRight: {
          id: 'mobilepayment-receipt-yes',
          title: 'Kyllä',
          continueToStep: '10b',
        },
      },
    },
    {
      step: 22,
      videos: ['/media/video/mobilepayment/payment-card-near.mp4'],
      actions: {
        title: 'Haluatko kuitin?',
        timeOut: {
          finished: {
            continueToStep: 23,
          },
        },
        actionLeft: {
          id: 'mobilepayment-receipt-no',
          title: 'En',
          continueToStep: 24,
        },
        actionRight: {
          id: 'mobilepayment-receipt-yes',
          title: 'Kyllä',
          continueToStep: 23,
        },
      },
    },
    {
      step: 23,
      videos: ['/media/video/mobilepayment/receipt.mp4', '/media/video/mobilepayment/receipt-2.mp4'],
      continueToStep: 24,
    },
    {
      step: 24,
      videos: ['/media/video/mobilepayment/lainaarahaa-viesti.mp4'],
      continueToStep: 25,
    },
    {
      step: 25,
      actions: {
        title: 'Autatko kaveria?',
        timeOut: {
          continueToNextStory: true,
        },
        actionLeft: {
          id: 'mobilepayment-no-help',
          title: 'Nyt en pysty',
          finished: { won: true, karma: 0 },
          continueToNextStory: true,
        },
        actionRight: {
          id: 'mobilepayment-friend-in-need',
          title: 'Totta kai',
          continueToStep: 30,
        },
      },
    },
    {
      step: 30,
      videos: ['/media/video/mobilepayment/20-euroa-laina-mobiilimaksu.mp4'],
      videoEndAction: {
        finished: { won: true, karma: 0.3 },
        continueToNextStory: true,
      },
    },
  ]
}
