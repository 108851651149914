import React, { Component } from 'react';
import _ from 'lodash'

import Actions from './Actions.jsx'

export default function({ news, actions }) {
  const { title, description, image, content } = news

  return <div>
    <div className="il-page il-news-page">
      <div className="flex flex-row il-header">
        <div className="flex-1"><img src="/media/icons/il/il.png" className="il-logo" /></div>
        <div className=""><img src="/media/icons/il/il2.png" className="il-menu" /></div>
      </div>

      <div className="il-article">
        <h1>{title}</h1>

        <p className="article-description">{description}</p>

        {image ? <img src={image} /> : ''}

        {content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : ''}
      </div>
    </div>

    <div className="news-actions">
      <Actions smallOverlay {...actions} />
    </div>
  </div>
}