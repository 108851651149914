import React, { Component } from 'react'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function() {
  return <div className="loading">
    <Loader
      type="Rings"
      color="#f3f3f3"
      height={184}
      width={184}
    />
  </div>
}