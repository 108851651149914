import React, { Component } from 'react'

import {
  EmailShareButton,
  EmailIcon,

  FacebookShareButton,
  FacebookIcon,

  FacebookMessengerShareButton,
  FacebookMessengerIcon,

  TelegramShareButton,
  TelegramIcon,

  TwitterShareButton,
  TwitterIcon,

  WhatsappShareButton,
  WhatsappIcon,

} from "react-share";

const title = 'Everyday'
const url = 'https://everydaygame.fi'

export default function() {
  return <div className="text-center share-icons py-md">
    <FacebookShareButton url={url}><FacebookIcon size={32} round={true} /></FacebookShareButton>
    <FacebookMessengerShareButton url={url} title={title}><FacebookMessengerIcon size={32} round={true} /></FacebookMessengerShareButton>
    <TelegramShareButton url={url} title={title}><TelegramIcon size={32} round={true} /></TelegramShareButton>
    <TwitterShareButton url={url} title={title}><TwitterIcon size={32} round={true} /></TwitterShareButton>
    <WhatsappShareButton url={url} title={title}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
    <EmailShareButton url={url} subject={title}><EmailIcon size={32} round={true} /></EmailShareButton>
  </div>
}