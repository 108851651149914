export default {
  storyId: 'ransomware',
  currentStep: 1,
  initialStep: 1,

  title: 'Ransomware',
  description: 'Internetissä on paljon epäilyttäviä tiedostoja ja sovelluksia. Niiden haitallisuutta ei välttämättä aina tunnista.',
  image: '/media/images/stories/kyber_game_7.jpg',

  achievements: [
    { id: "ransomware-no-3", title: 'Lead me not', image: '/media/images/achievements/leadmenot.png', description: 'Et ladannut ransomwarea' },
  ],

  steps: [
    {
      step: 1,
      videos: ['/media/video/walking.mp4'],
      continueToStep: 2,
    },
    {
      step: 2,
      videos: ['/media/video/ransomware/ransomware.mp4'],
      actions: {
        title: 'Haluatko ladata tämän sovelluksen?',
        timeOut: {
          finished: { won: false, karma: -0.1, title: 'Aika loppui. Peli ohi!' },
        },
        actionLeft: {
          id: 'ransomware-no',
          title: 'En tarvitse tätä',
          continueToStep: '2b',
        },
        actionRight: {
          id: 'ransomware-trap',
          title: 'Näyttää mielenkiintoiselta!',
          continueToStep: 3,
        },
      },
    },
    {
      step: '2b',
      videos: ['/media/video/ransomware/ransomware.mp4'],
      actions: {
        title: 'Oletko ihan varma, ettet halua ladata tätä?',
        timeOut: {
          finished: { won: false, karma: -0.1, title: 'Aika loppui. Peli ohi!' },
        },
        actionLeft: {
          id: 'ransomware-no-2',
          title: 'En tarvitse tätä',
          continueToStep: '2c',
        },
        actionRight: {
          id: 'ransomware-trap',
          title: 'Näyttää mielenkiintoiselta!',
          continueToStep: 3,
        },
      },
    },
    {
      step: '2c',
      videos: ['/media/video/ransomware/ransomware.mp4'],
      actions: {
        title: 'Kokeile nyt! Se on ilmainen!',
        timeOut: {
          finished: { won: false, karma: -0.1, title: 'Aika loppui. Peli ohi!' },
        },
        actionLeft: {
          id: 'ransomware-no-3',
          title: 'En tarvitse tätä',
          finished: {
            won: true, karma: 0.4,
            title: 'Kaikkea ei pidä klikata',
            info: '<p>Hyvin toimittu. Silmille hyppivät mainokset voivat olla ärsyttäviä, mutta niille periksi antaminen ei ole ratkaisu. Älä anna tuntemattomille sivustoille tai sovelluksille oikeuksia käyttää kännykkääsi, äläkä lataa mitään, mikä vaikuttaa epäilyttävältä. Ylipäätään kännykälle ei kannata ladata mitään, mitä ei löydy sovelluskaupasta.</p>'
          },
        },
        actionRight: {
          id: 'ransomware-trap',
          title: 'Näyttää mielenkiintoiselta!',
          continueToStep: 3,
        },
      },
    },
    {
      step: 3,
      videos: ['/media/video/ransomware/kiristys-notif.mp4'],
      waitForClick: true,
      videoEndAction: {
        finished: {
          won: false, karma: -0.35,
          title: 'Mieti, mitä lataat.',
        }
      }
    },
  ]
}
