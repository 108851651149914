import React, { Component } from 'react';

import { navigate } from '../actions/navigate'
import { startStory } from '../actions/storyActions'
import { getLocalStorage } from '../lib/localStorage'

import KarmaBar from './story/KarmaBar.jsx'

class Start extends Component {
  start() {
    const { completedStories = [] } = getLocalStorage()

    const tutorialCompleted = completedStories.includes('tutorial')
    if (tutorialCompleted) {
      navigate(false)
      return
    }

    startStory('tutorial')
  }

  render() {
    return <div className="flex flex-col text-center px-6 py-6 home tutorial bg-dark bg-cover home-bg-1">
      <div className="flex flex-col justify-center flex-1 px-xl">
        <img src="/media/images/logo/everyday_1_w.png" alt="Everyday" />
      </div>

      <div>
        <div className="button button-primary" onClick={() => this.start()}>
          Aloita
        </div>
        <div className="text-xs text-gray my-lg">1.5</div>
      </div>
    </div>
  }
}

export default Start;
