export default {
  storyId: 'wifi',
  currentStep: 1,
  initialStep: 1,

  title: 'WiFi ja VPN',
  description: 'Vapaita verkkoja on tarjolla kaikkialla. Kuinka turvallista niiden käyttö on?',
  image: '/media/images/stories/kyber_game_2.jpg',

  achievements: [
    { id: "wifi-cafe", title: 'Cup o’ joe.', image: '/media/images/achievements/cupojoe.png', description: 'Valitse ”lataa kahvilassa” vaihtoehto' },
    { id: "wifi-vpn", title: 'Better safe', image: '/media/images/achievements/bettersafe.png', description: 'Asensit VPN:n pelissä. Hyvä. Tee sama seuraavasti oikeasti.' },
  ],

  steps: [
    {
      step: 1,
      videos: ['/media/video/wifi/transport.mp4',],
      actions: {
        title: 'Akkusi on loppumassa!',
        subtitle: 'Miten toimit?',
        timeOut: {
          finished: { won: false, karma: -0.1, title: 'Game over, virta loppui.' },
        },
        actionLeft: {
          id: 'wifi-home',
          title: 'Antaa loppua, lataan kotona',
          finished: { won: false, karma: -0.1, title: 'Akkusi loppui.' },
        },
        actionRight: {
          id: 'wifi-cafe',
          title: 'Pysähdyn kahvilaan lataamaan',
          continueToStep: '2a',
        },
      },
      preloadStep: '2a',
    },
    {
      step: '2a',
      videos: ['/media/video/wifi/door-enter-1.mp4', '/media/video/wifi/door-enter-2.mp4'],
      continueToStep: '2b',
    },
    {
      step: '2b',
      videos: ['/media/video/wifi/phone-charge.mp4'],
      continueToStep: '2c',
    },
    {
      step: '2c',
      videos: ['/media/video/wifi/wifi-notif.mp4'],
      waitForClick: true,
      actions: {
        title: 'Miten toimit?',
        timeOut: {
          finished: { won: false, karma: -0.35 },
        },
        actionLeft: {
          id: 'wifi-vpn',
          title: 'Varmistan että verkko on turvallinen',
          continueToStep: '10a',
        },
        actionRight: {
          id: 'wifi-connect',
          title: 'Liityn verkkoon asiaa miettimättä',
          continueToStep: '10b',
        },
      },
    },
    {
      step: '10a',
      actions: {
        title: 'Tiedätkö miten VPN toimii?',
        actionLeft: {
          id: 'wifi-help',
          title: 'Miten?',
          finished: { won: true, karma: 0.2, title: 'Mikä on VPN?', info: '<p>VPN (Virtual Private Network) eli virtuaalinen erillisverkko on tapa, jolla kaksi tai useampi verkkoa voidaan yhdistää ja näin luoda näennäisesti yksityisen verkon. VPN on hyvä ja verrattaen halpa tapa pitää tietojasi turvassa kun selailet verkkoa.</p><h2>Miten VPN asennetaan?</h2><p>Palvelun asentaminen on yhtä helppoa kuin minkä tahansa kännykkäsovellus. Kun valitset itsellesi VPN-ohjelmaa, kannattaa lukea palvelun saamia arvioita ja katsoa, kuka sovelluksen on kehittänyt. Jos palvelu on verifioitu sovelluskaupassa, on se yleensä turvallinen.</p>' },
        },
        actionRight: {
          id: 'wifi-info',
          title: 'Kyllä',
          finished: { won: true, karma: 0.2, title: 'Pieni teko, suuri vaikutus', info: 'Hienoa! Moni tietää miten VPN toimii, mutta harvempi vaivautuu sitä käyttämään. Muista, että VPN toimii sekä tietokoneella, että puhelimellasi.' },
        },
      },
    },
    {
      step: '10b',
      videos: ['/media/video/wifi/hakkeroitu-notif.mp4'],
      waitForClick: true,
      actions: {
        title: 'Miten toimit?',
        timeOut: {
          finished: { won: false, karma: -0.35, title: 'Puhelimesi hajosi.' },
        },
        actionLeft: {
          id: 'wifi-hacked-friend',
          title: 'Kysy apua kaverilta',
          continueToStep: 20,
        },
        actionRight: {
          id: 'wifi-hacked-internet',
          title: 'Hae tietoa internetistä',
          continueToStep: 21,
        },
      },
    },
    {
      step: 20,
      videos: ['/media/video/wifi/hakkeroitu-chat.mp4'],
      videoEndAction: {
        finished: { won: true, karma: 0.1 },
        continueToNextStory: true,
      },
    },
    {
      step: 21,
      videos: ['/media/video/wifi/hakukone.mp4',],
      waitForClick: true,
      videoEndAction: {
        finished: { won: true, karma: 0.15, title: 'Mitä voit tehdä, jos puhelimesi on hakkeroitu?', info: '<p>Sovelluskaupoista löytyy monia virustorjuntaohjelmia, joilla haittaohjelman voi poistaa. Valitse sellainen ohjelma, jonka sovelluskauppa on verifioinut ja mielellään sellainen tietoturvayhtiö, jonka tiedät olevan luotettava.</p><p>Kun olet poistanut haittaohjelmat puhelimestasi, päivitä seuraavaksi kaikkien tärkeiden tiliesi salasanat. Niihin sisältyvät kaikki sosiaalinen media, sähköpostit, verkkopankit ja mobiilimaksamissovellukset, Apple ID ja Google-tilit sekä puhelimen oma salasana.</p><p>Tarkista myös kaikki verkkosivut, joilla olet tehnyt ostoksia luottokortillasi. Jos siellä on tehty luvattomia ostoja, ilmoita siitä pankillesi pyydä heitä palauttamaan rahasi.</p>' },
      },
    },
  ]
}
