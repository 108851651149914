import React, { Component } from 'react'
import { isAchieved } from '../lib/achievements'

export default function({ achievements, onClick = () => {} }) {
  if (!achievements) {
    return null
  }

  const total = achievements.length
  const achieved = achievements.filter(achievement => isAchieved(achievement)).length

  return <div
    className="flex flex-row text-white text-sm"
    onClick={() => onClick()}
  >
    <div className="flex-1">Saavutukset</div>
    <div className="flex-1 text-right"><span className="button-small font-bold">{achieved} / {total}</span></div>
  </div>
}