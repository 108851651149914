import React, { Component } from 'react'

import { navigate } from '../actions/navigate'

export default function({ route }) {
  return <div className="flex flex-row mb-6">
    <div className="flex flex-row icon-container">
      {route ? <>
        <img src="/media/icons/Path 24@2x.png" onClick={() => navigate(false)} />
      </> : <>
        {/*<div className="flex-1 text-sm line-height-24">Tervetuloa pelaaja #{_.random(1000, 9999)}</div>*/}
      </>}
    </div>
    <div className="flex flex-1 justify-center">
      <img id="header-logo" className="px-sm" src="/media/images/logo/everyday_1_w.png" alt="Everyday" />
    </div>
    <div className="flex icon-container">
      {/*<img className="icon" src="/media/icons/settings_applications-24px@2x.png" onClick={() => navigate('settings')} />*/}
    </div>
  </div>
}