import React, { Component } from 'react';
import _ from 'lodash'

import Actions from './Actions.jsx'

export default function({ email, actions }) {
  const { title, content } = email

  return <div className="email">
    <div className="content px-3 py-6">
      <h4 className="font-bold mb-3">{title}</h4>

      {content ? <div className="text-sm" dangerouslySetInnerHTML={{ __html: content }} /> : ''}
    </div>

    <div className="news-actions">
      <Actions smallOverlay {...actions} />
    </div>
  </div>
}