import React, { Component } from 'react';

import StoryCard from './StoryCard.jsx'
import Achievements from './Achievements.jsx'
import AchievementIcon from './AchievementIcon.jsx'
import Header from './Header.jsx'
import { startStory } from '../actions/storyActions'

class StoryInfoPage extends Component {
  componentDidMount() {
  }


  render() {
    const { storyInfo } = this.props
    const story = storyInfo

    return <div className="p-6 home bg-dark">
      <Header {...this.props} />

      <div className="flex flex-row highlighted-story mb-12">
        <div className="flex flex-1 pr-6">
          <StoryCard {...story} imageOnly="true" />
        </div>

        <div className="flex flex-col justify-center flex-1">
          <h2 className="font-bold mb-3">{story.title}</h2>
          <p className="mb-6">{story.description}</p>

          <div className="button button-primary" onClick={() => startStory(story.storyId, true)}>
            Pelaa
          </div>
        </div>
      </div>

      {story.achievements ? <div>
        <Achievements {...story} />

        <div className="flex flex-row flex-wrap -mr-md">
          {story.achievements.map(achievement => {
            const { title } = achievement
            return <AchievementIcon {...achievement} key={title} />
          })}
        </div>
      </div> : ''}
    </div>
  }
}

export default StoryInfoPage;
