import _ from 'lodash'
import { state, setState } from '../lib/state'
import stories from '../stories/index'
import { navigate } from './navigate'
import { closePushMessage, maybeSchedulePushMessage } from './pushMessage'
import { getLocalStorage, setLocalStorage } from '../lib/localStorage'
import { setMediaCache } from '../lib/preloadMedia'
import analytics from '../lib/analytics'


export function getNextStory() {
  const { completedStories = [] } = getLocalStorage()

  const nextStory = state.stories.find(story => !story.hidden && !completedStories.includes(story.storyId))

  if (!nextStory) {
    return false
  }

  return nextStory
}

export function startStory(storyId, doClosePushMessage) {
  if (process.env.NODE_ENV === 'development') {
    console.info('--- startStory ---', storyId)
  }

  if (doClosePushMessage) {
    closePushMessage()
  }

  document.getElementById('video-background').pause()

  const story = Object.assign({}, stories[storyId])

  if (story.steps) {
    story.steps = story.steps.map(step => {
      if (step.videos) {
        step.videoIndex = _.random(0, step.videos.length - 1)
      }

      return step
    })

    prepareStep(story, story.currentStep)
  } else if (story.quiz) {
    story.quiz = story.quiz.map(question => {
      question.choices = _.shuffle(question.choices)
      return question
    })
  }

  story.currentStep = story.initialStep

  navigate('story', { story })

  analytics('story', 'started', storyId)
}

export function viewStoryInfo(storyId) {
  const storyInfo = Object.assign({}, stories[storyId])

  navigate('storyInfo', { storyInfo })
}

function updateKarma(change) {
  if (change === undefined) {
    return
  }

  const storage = getLocalStorage()

  const oldKarma = storage.karma || 0
  let karma = oldKarma + change
  if (karma >= 0) {
    karma = _.clamp(karma, 0.05, 1)
  } else {
    karma = _.clamp(karma, -1, -0.05)
  }
  setState({ oldKarma, karma })

  storage.karma = karma

  setLocalStorage(storage)
}

export function doAction({ finished, continueToStep, id, continueToNextStory, doClosePushMessage, score, resetScore }) {
  if (process.env.NODE_ENV === 'development') {
    console.log('--- doAction ---', finished, continueToStep, id, continueToNextStory)
  }

  prepareStep(state.story, continueToStep)

  let totalScore = state.story.totalScore
  if (resetScore) {
    totalScore = 0
  }
  if (score) {
    totalScore += score
  }
  if (finished && totalScore !== undefined) {
    finished.karma = (totalScore - 3) / 10
  }

  if (doClosePushMessage) {
    closePushMessage()
  }

  const story = Object.assign({}, state.story, { finished, currentStep: continueToStep, totalScore })

  if (id) {
    saveAction(id)

    analytics('action', 'selected', id)
  }

  if (finished) {
    closePushMessage()

    saveCompletedStory(story.storyId)
    analytics('story', finished.won ? 'won' : 'over', story.storyId)

    updateKarma(finished.karma)

    // avoid chaining pushMessage stories
    if (!story.pushMessage) {
      maybeSchedulePushMessage('finished')
    }
  }

  if (continueToNextStory) {
    const nextStory = getNextStory()

    if (nextStory) {
      startStory(nextStory.storyId)
      return
    }
  }

  setState({ story })
}



async function prepareStep(story, stepToPrepare) {
  const step = _.find(story.steps, { step: stepToPrepare })

  if (!step) {
    return
  }


  if (step.videos) {
    if (process.env.NODE_ENV === 'development') {
      console.info('--- prepare video ---', stepToPrepare, step.videos[step.videoIndex])
    }
    await setMediaCache(step.videos[step.videoIndex])
  }

  if (step.continueToStep) {
    prepareStep(story, step.continueToStep)
  }
  if (step.preloadStep) {
    prepareStep(story, step.preloadStep)
  }
}

function saveAction(id) {
  const storage = getLocalStorage()
  storage.savedActions = storage.savedActions || []

  if (!storage.savedActions.includes(id)) {
    storage.savedActions.push(id)
  }

  // console.info('savedActions', storage.savedActions)

  setLocalStorage(storage)
}

function saveCompletedStory(id) {
  const storage = getLocalStorage()
  storage.completedStories = storage.completedStories || []

  if (!storage.completedStories.includes(id)) {
    storage.completedStories.push(id)
  }

  // console.info('completedStories', storage.completedStories)

  setLocalStorage(storage)
}
