import React, { Component } from 'react';

import { state } from '../lib/state'
import { closePushMessage } from '../actions/pushMessage'
import { startStory } from '../actions/storyActions'

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  WhatsappIcon,

} from "react-share";

class PushMessage extends Component {
  onClick() {
    const { storyId, closeOnClick } = this.props.pushMessage

    if (storyId && (!state.story || state.story.storyId !== storyId)) {
      startStory(storyId)
    }

    clearTimeout(this.timeout)

    if (closeOnClick) {
      closePushMessage()
    }
  }

  componentDidUpdate(prevProps) {
    // currently all pushMessages have storyId
    if (prevProps.pushMessage.storyId === this.props.pushMessage.storyId) {
      return
    }

    const { timeout } = this.props.pushMessage

    if (timeout) {
      this.timeout = setTimeout(closePushMessage, timeout)
    }
  }

  renderType() {
    const { type } = this.props.pushMessage

    switch (type) {
      case 'facebook':
        return <>
          <FacebookIcon round={true} />
          Facebook
        </>

      case 'email':
        return <>
          <EmailIcon round={true} />
          Email
        </>

      case 'messenger':
        return <>
          <FacebookMessengerIcon round={true} />
          Messenger
        </>

      case 'whatsapp':
        return <>
          <WhatsappIcon round={true} />
          WhatsApp
        </>

      case 'sms':
      default:
        return <>
          <EmailIcon round={true} />
          Viestit
        </>
    }
  }

  render() {
    const { from, message, type } = this.props.pushMessage

    return <div className={`push-message ${message ? 'open' : ''}`} onClick={() => this.onClick()}>
      <div className="flex flex-row mb-sm">
        <div className="flex-1 text-sm notification-title">
          {this.renderType()}
        </div>
        <div>nyt</div>
        </div>
      <div className="text-sm font-bold">{from}</div>
      <div>{message}</div>
    </div>
  }
}

export default PushMessage;
