const STORAGE_KEY = 'everydayStorage'

export function getLocalStorage() {
  try {
    const storage = localStorage.getItem(STORAGE_KEY);
    if (!storage) {
      return {}
    }

    return JSON.parse(storage)
  } catch(error) {
    console.info('error parsing json', error)
    return {}
  }
}

export function setLocalStorage(storage) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(storage))
}
