export default {
  storyId: 'password',
  currentStep: 1,
  initialStep: 1,

  title: 'Salasanat',
  description: 'Salasanat ovat yksityisyydensuojan ensimmäinen rintama, mutta minkälainen salasana on paras?',
  image: '/media/images/stories/kyber_game_5.jpg',

  achievements: [
    { id: "password-strong", title: 'Protected', image: '/media/images/achievements/protected.png', description: 'Valitse vahvin salasana' },
  ],

  steps: [
    {
      step: 1,
      videos: ['/media/video/in-bed.mp4'],
      continueToStep: 2,
    },
    {
      step: 2,
      videos: ['/media/video/password/spam-viesti.mp4'],
      actions: {
        title: 'Valitse uusi salasana',
        timeOut: {
          finished: { won: false, karma: -0.1 },
        },
        actionLeft: {
          id: 'password-weak',
          title: "S4L4S4N4",
          finished: { won: false, karma: -0.25, title: 'Miten hakkerit yrittävät murtaa salasanasi?', info: '<p>Suurin osa salasanojen hakkerointiyrityksistä on automatisoitua. Siksi salasana on sitä helpompi hakkeroida, mitä lyhyempi ja yleisempi se on. Jos salasanasi on "Password", voit olla varma, että ennemmin tai myöhemmin joku sen hakkeroi.</p></p>Toinen, harvinaisempi tapa on yrittää arvata salasanasi manuaalisesti. Tästä syystä oma tai lemmikin nimi, syntymäaika tai muu helposti arvattava salasana ei ole hyvä. Joulukuussa 2019 esimerkiksi Yhdysvaltain presidentin Twitter-tilille murtauduttiin arvaamalla hänen salasanansa.</p>' },
        },
        actionRight: {
          id: 'password-weak',
          title: 'salasana',
          finished: { won: false, karma: -0.35, title: 'Miten hakkerit yrittävät murtaa salasanasi?', info: '<p>Suurin osa salasanojen hakkerointiyrityksistä on automatisoitua. Siksi salasana on sitä helpompi hakkeroida, mitä lyhyempi ja yleisempi se on. Jos salasanasi on "Password", voit olla varma, että ennemmin tai myöhemmin joku sen hakkeroi.</p></p>Toinen, harvinaisempi tapa on yrittää arvata salasanasi manuaalisesti. Tästä syystä oma tai lemmikin nimi, syntymäaika tai muu helposti arvattava salasana ei ole hyvä. Joulukuussa 2019 esimerkiksi Yhdysvaltain presidentin Twitter-tilille murtauduttiin arvaamalla hänen salasanansa.</p>' },
        },
        actionLeft2: {
          id: 'password-strong',
          title: "SalasanaSalasanaSalaSana123",
          finished: { won: true, karma: 0.2, title: 'Turvallinen salasana', info: '<p>Salasanalta vaaditaan usein numero, erikoismerkki ja vähintään yksi iso kirjain. Salasanan pituudella on kuitenkin enemmän merkitystä kuin sen sisältämillä merkeillä. Esimerkiksi "kohtuullisenturvallinensalasanaesimerkki" on turvallisempi kuin "Salasana1".</p>' }
        },
      },
    },
  ]
}

// function generatePassword(passwordLength = 12) {
//   const lowerCase = 'abcdefghijklmnopqrstuvwxyz'
//   const upperCase = lowerCase.toUpperCase()
//   const numberChars = '0123456789'
//   const specialChars = '!@$%?'

//   let generatedPassword = ''
//   let restPassword = ''

//   const restLength = passwordLength % 4
//   const usableLength = passwordLength - restLength
//   const generateLength = usableLength / 4

//   const randomString = (char) => {
//     return char[Math.floor(Math.random() * (char.length))]
//   }
//   for (let i = 0; i <= generateLength - 1; i++) {
//     generatedPassword += `${randomString(lowerCase)}${randomString(upperCase)}${randomString(numberChars)}${randomString(specialChars)}`
//   }

//   for (let i = 0; i <= restLength - 1; i++) {
//     restPassword += randomString([...lowerCase, ...upperCase, ...numberChars, ...specialChars])
//   }

//   return generatedPassword + restPassword
// }