const subscribers = []

export const state = {}

export function setState(newState) {
  Object.assign(state, newState)

  subscribers.forEach(fn => {
    fn(state)
  })
}

export function stateSubscribe(fn) {
  subscribers.push(fn)
}
