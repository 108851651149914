// seaoson 1
import backup from './backup.js'
import copyright from './copyright.js'
import mobilepayment from './mobilepayment.js'
import password from './password.js'
import ransomware from './ransomware.js'
import tutorial from './tutorial.js'
import wifi from './wifi.js'

// season 2
import authenticator from './s2/authenticator.js'
import blackmail from './s2/blackmail.js'
import bully from './s2/bully.js'
import cyberquiz from './s2/cyberquiz.js'
import fakemail from './s2/fakemail.js'
import fakenews from './s2/fakenews.js'
import fakeprofile from './s2/fakeprofile.js'
import phishing from './s2/phishing.js'
import picture from './s2/picture.js'
import school from './s2/school.js'

export default {
  tutorial,
  wifi,
  mobilepayment,
  copyright,
  backup,
  password,
  ransomware,

  authenticator,
  blackmail,
  bully,
  cyberquiz,
  fakemail,
  fakenews,
  fakeprofile,
  phishing,
  picture,
  school,
}