import React, { Component } from 'react';

const TIMEOUT = 1000

export default class KarmaBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      displayKarma: props.oldKarma,
    }
  }

  componentDidMount() {
    // crossing over 0
    if (Math.sign(this.props.oldKarma) !== Math.sign(this.props.karma)) {
      setTimeout(() => {
        this.setState({ displayKarma: 0 })

        setTimeout(() => {
          this.setState({ displayKarma: this.props.karma })
        }, TIMEOUT / 2)
      }, TIMEOUT / 2)
    } else {
      setTimeout(() => {
        this.setState({ displayKarma: this.props.karma })
      }, TIMEOUT)
    }
  }


  render() {
    const { displayKarma } = this.state

    const positiveKarma = displayKarma >= 0

    let barWidth = 50
    let overlayWidth = 50

    if (positiveKarma) { // make bar longer
      barWidth += displayKarma * 50
    } else { // make overlay shorter
      overlayWidth -= displayKarma * -50
    }

    return <div className="my-lg">
      <div className="mb-sm text-sm text-center">Digitaalinen karmasi</div>

      <div className="karma-bar">
        <div className={`karma ${positiveKarma ? 'positive' : 'negative'}`} style={{ width: barWidth + '%' }} />
        <div className="karma-overlay" style={{ width: overlayWidth + '%' }} />
      </div>
    </div>
  }
}