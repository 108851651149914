import stories from './stories/index'

module.exports = reset => {
  return {
    stories: [
      stories.tutorial,
      stories.wifi,
      stories.mobilepayment,
      stories.copyright,
      stories.backup,
      stories.password,
      stories.ransomware,

      stories.authenticator,
      stories.blackmail,
      stories.bully,
      stories.cyberquiz,
      stories.fakemail,
      stories.fakenews,
      // stories.fakeprofile,
      stories.phishing,
      stories.picture,
      stories.school,
    ],
    pushMessage: false,
    story: false,
    route: 'start',
  }
}