export default {
  hidden: true,

  storyId: 'picture',
  currentStep: 1,
  initialStep: 1,

  pushMessage: {
    message: 'Voitko laittaa mulle sen kuvan kun [name] sammui bileissä?',
    type: 'messenger',
    pushCondition: 'finished',
  },

  steps: [
    {
      step: 1,
      actions: {
        actionLeft: {
          id: 'picture-good',
          title: 'En tietenkään',
          finished: { won: true, karma: 0.1 },
        },
        actionRight: {
          id: 'picture-bad',
          title: 'Totta hitossa!',
          finished: { won: true, karma: -0.2, info: '<p>Nolojen kuvien jakaminen voi olla paitsi huono idea, myös rikos. Et halua nolata kaveria etkä syyllistyä yksityiselämää loukkaavan tiedon levittämiseen, ethän?</p>' },
        },
      },
    },
  ]
}
