import React, { Component } from 'react';

import { startStory } from '../actions/storyActions'

class Tutorial extends Component {
  render() {
    return <div className="flex flex-col px-6 py-12 home bg-dark bg-cover">
      <div className="flex flex-col flex-1">
        <h1 className="mb-md">Tervetuloa!</h1>
        <p className="mb-lg">Everyday on nimensä mukaisesti peli, jonka mikä tahansa tilanne voisi sattua sinulle mikä tahansa päivä. Pelissä testataan tietojasi ja osaamistasi arjen digitaalisessa ympäristössä. Everyday: Season 1 keskittyy niin tavallisiin tilanteisiin, että jokaisen pitäisi pystyä pelaamaan peli läpi. Pelissä voit kokeilla turvallisessa ympäristössä, mitä tapahtuu, jos tietoturvasta ei pidä huolta.</p>
      </div>
      <div className="text-center">
        <div className="button button-primary" onClick={() => startStory('tutorial')}>
          Seuraava
        </div>
      </div>
    </div>
  }
}

export default Tutorial;
