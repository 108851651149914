import React, { Component } from 'react'

import Achievements from './Achievements.jsx'

export default function(story) {
  const { title, image, storyId, imageOnly, isNew, onClick = () => {} } = story

  return <div
    onClick={() => onClick(storyId)}
    className="story-card relative flex flex-1 items-end pointer"
    style={{ backgroundImage: `url("${image}")` }}
  >
    {isNew ? <span className="is-new button-small font-bold">uusi</span> : ''}
    {imageOnly ? '' : <div className="flex-1">
      <div className="text-lg font-bold mb-3">{title}</div>
      <Achievements {...story} />
    </div>}
  </div>
}