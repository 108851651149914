import React, { Component } from 'react';
import PushMessage from './components/PushMessage'
import Start from './components/Start'
import Tutorial from './components/Tutorial'
import StoryInfoPage from './components/StoryInfoPage'
import Story from './components/story/Story'
import AchievementsPage from './components/AchievementsPage'
import Home from './components/Home'

class App extends Component {
  componentDidMount() {
  }


  content() {
    switch (this.props.route) {
      case 'start':
        return <Start {...this.props} />

      case 'tutorial':
        return <Tutorial {...this.props} />

      case 'storyInfo':
        return <StoryInfoPage {...this.props} />

      case 'story':
        return <Story {...this.props} />

      case 'achievements':
        return <AchievementsPage {...this.props} />

      default:
        return <>
          <Home {...this.props} />
        </>
    }
  }

  render() {
    return <>
      <PushMessage {...this.props} />
      {this.content()}
    </>
  }
}

export default App;
